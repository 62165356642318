import { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  const [expanded, toggleExpanded] = useState(false);
  const [show, setShow] = useState(false);

  const showMenu = (e: any) => {
    setShow(!show);
  };
  const hideMenu = (e: any) => {
    setShow(false);
  };

  return (
    <header>
      <Navbar
        expanded={expanded}
        onToggle={() => toggleExpanded(!expanded)}
        expand="lg"
        className="custom-navbar"
        id="navbar"
        collapseOnSelect
      >
        <Navbar.Brand as={Link} to="/" onClick={() => toggleExpanded(false)}>
          <img src="/images/logo.png" alt="" width="236" />
          <img src="/images/logo-icon.png" alt="" className="mobile-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mx-auto my-2 my-lg-0"
            navbarScroll
            style={{ maxHeight: 100 }}
          >
            <Nav.Item>
              <Nav.Link
                onClick={() => toggleExpanded(false)}
                className="nav-link"
                href="https://app.fantomstarter.io/projects"
              >
                Projects
              </Nav.Link>
            </Nav.Item>
            <Nav.Item onSelect={() => toggleExpanded(false)}>
              <Nav.Link href="/#about">Raise crypto capital</Nav.Link>
            </Nav.Item>
            <NavDropdown
              title="Litepaper"
              id="nav-dropdown"
              show={show}
              onMouseEnter={showMenu}
              onMouseLeave={hideMenu}
            >
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.1"
                target="blank"
                href="https://fantomstarterio.github.io/docs/English_Litepaper_v3.0.pdf"
              >
                English
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.2"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Chinese_Litepaper_v3.0.pdf"
              >
                Chinese
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/French_ Litepaper_v3.0.pdf"
              >
                French
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/German_Litepaper_v3.0.pdf"
              >
                German
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/India_Litepaper_v3.0.pdf"
              >
                Indian
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Indonesia_Litepaper_v3.0.pdf"
              >
                Indonesian
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Italia_Litepaper_v3.0.pdf"
              >
                Italian
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Korea_Litepaper_v3.0.pdf"
              >
                Korean
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Pakistan_Litepaper_v3.0.pdf"
              >
                Pakistan
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Persian_Litepaper_v3.0.pdf"
              >
                Persian
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Portuguese_Litepaper_v3.0.pdf"
              >
                Portuguese
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Russian_Litepaper_v3.0.pdf"
              >
                Russian
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => toggleExpanded(false)}
                eventKey="4.3"
                target="blank"
                href="https://fantomstarterio.github.io/docs/Vietnamese_Litepaper_v3.0.pdf"
              >
                Vietnamese
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link
                onClick={() => toggleExpanded(false)}
                className="nav-link"
                href="https://medium.com/@fantomstarter"
                target="_blank"
              >
                Blog
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => toggleExpanded(false)}
                className="nav-link"
                href="https://equalizer.exchange/swap"
                target="_blank"
              >
                Get $FS
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="nav-right">
            <Nav.Link
              href="https://app.fantomstarter.io/"
              className="common-btn try-the-alpha"
            >
              Launch the app
            </Nav.Link>
          </div>
        </Navbar.Collapse>
        <Nav.Link
          href="https://app.fantomstarter.io/"
          className="common-btn mobile-header-button try-the-alpha"
        >
          Launch app
        </Nav.Link>
      </Navbar>
    </header>
  );
};
export default Header;
