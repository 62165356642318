import { useEffect } from "react";
// import video from './assets/videos/bg-up.mp4';

const Banner = () => {
  useEffect(() => {
    const secA = document.getElementById("secA")?.offsetTop || 0;
    const secB = document.getElementById("secB")?.offsetTop || 0;
    //const secC = document.getElementById('secC')?.offsetTop || 0;

    const handleScroll = () => {
      const mq = window.matchMedia("(max-width: 768px)");
      if (mq.matches) {
        const plans = document.getElementsByClassName(
          "plan-box"
        ) as any as Array<HTMLElement>;
        for (let i = 0; i < plans.length; i++) {
          plans[i].classList.remove("active");
        }

        const st = document.documentElement.scrollTop;
        if (st < secA && st < secB) {
          document.getElementById("secA")?.classList.add("active");
        } else if (st > secA && st <= secB) {
          document.getElementById("secB")?.classList.add("active");
        } else if (st > secB) {
          document.getElementById("secC")?.classList.add("active");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="banner">
      <video
        playsInline
        autoPlay
        muted
        loop
        poster="/assets/images/top-background-2a.png"
        className="videoUp"
        id="videoUpDesktop"
      >
        <source src="/videos/bg-up.mp4" type="video/mp4" />
      </video>

      <video
        playsInline
        autoPlay
        muted
        loop
        className="videoUpMobile"
        id="videoUpMobile"
      >
        <source src="/videos/bg-up-mobile.mp4" type="video/mp4" />
      </video>

      <div className="container">
        <div className="banner-inner">
          <div className="banner-text">
            <h1>Get Early Access to the Hottest Web3 Deals</h1>
            <p>
              Be part of the future of finance with 
              accesss to the hottest token and NFT deals.
            </p>
            <a
              href="https://app.fantomstarter.io/staking"
              className="common-btn gradiant-btn"
              target="_blank"
              rel="noreferrer"
            >
              Unlock Exclusive Access
            </a>
          </div>

          <div className="banner-img">
            <img src="/images/logo-icon.png" alt="" width="254" />
          </div>
        </div>
        <div className="center-logo">
          {/* <div className="logo-list">
                    <img src="/images/logo-fantom.png" alt="" />
                </div> */}
{/*           <div className="logo-list">
            <img src="/images/partners/logo-any-swap.png" alt="" />
          </div> */}
          <div className="logo-list">
            <img src="/images/partners/logo-spark.png" alt="" />
          </div>
          <div className="logo-list">
            <img src="/images/partners/logo-signum-capital.png" alt="" />
          </div>
          <div className="logo-list">
            <img src="/images/partners/zbs_capital.png" alt="" />
          </div>
          <div className="logo-list">
            <img src="/images/partners/logo-ankr.svg" alt="" />
          </div>
        </div>
        <div className="plan-outer">
          <h2 className="text-center">
            Invest <br />
            with <br />
            the best{" "}
          </h2>
        </div>
        <div className="plan-box-outer">
          <div className="plan-box" id="secA">
            <h3>Data scientists, AI and decentralized vetting.</h3>
            <p>
              Gain peace of mind knowing you're investing in projects that are
              carefully curated and DAO-vetted for accuracy and reliability.
              Invest smart and secure, with the help of our proprietary curation
              score.
            </p>
          </div>
          <div className="plan-box" id="secB">
            <h3>Make your idea a reality faster</h3>
            <p>
              Access the best minds in dev, marketing, and funding to give your
              project the boost it needs. With the Web3 accelerator, you can get
              strategic introductions to the right network of KOLs, trusted
              potential partners, and other benefits that will help you
              accelerate your ideas and achieve success.
            </p>
          </div>
          <div className="plan-box" id="secC">
            <h3>Native multichain experience</h3>
            <p>
              Unlock the full potential of decentralized investment with
              multi-chain interoperability! Now you can easily select your
              favorite blockchain network and participate in innovative
              opportunities across multiple networks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
